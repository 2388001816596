.userTags {
  @apply border border-dashed rounded-full py-[10px] px-4;
  color: #535A79;
}

.coordinador {
  border-color: #03A5C9;
  background-color: #BCF2FE;
}

.coordinador-dim {
  background-color: #BCE3EB;
}

.jefe {
  border-color: #3A19B3;
  background-color: #DCD4F9;
}

.jefe-dim {
  background-color: #C6C1E7;
}

.brigadista {
  border-color: #19B368;
  background-color: #D4F9E7;
}

.brigadista-dim {
  background-color: #C5E2DC;
}

.usersCard {
  @apply border border-dashed rounded flex py-3 px-2;
}