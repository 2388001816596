.steps {
  @apply flex items-center justify-evenly mb-6;
}

.step {
  @apply flex justify-center items-center rounded-full p-0;
  color: #6F6C90;
  min-width: 34px;
  min-height: 34px;
  background-color: #EFF0F6;
}

.divider {
  width: 70px;
  height: 6px;
  border-radius: 40px;
  margin: 0 1.5rem;
  display: inline-block;
  background-color: #EFF0F6;
}

.active, .activeDiv {
  background-color: @primary-color;
  color: #fff;

  &:hover, &:focus {
    background-color: @primary-color;
    color: #fff
  }
}

@primary-color: rgb(89, 50, 234);@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #fe6969;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@modal-mask-bg: fade(#fff, 85%);@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);