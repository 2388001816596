.groupCard {
  @apply relative mb-7 pb-4 pt-6 px-[18px] bg-lightgrey-300 border rounded-md border-dashed border-lightgrey-400 flex justify-between items-end;
}

.groupName {
  @apply py-2 pl-3 pr-16 bg-white text-xs text-lightgrey-400 border rounded absolute left-4 top-0;
  transform: translateY(-50%);
  border-color: #E9E2E3;
}

.groupDelete {
  @apply absolute top-0 right-0 rounded-tr-md rounded-bl-md bg-danger py-1 px-5 text-white;
}