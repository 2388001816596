.menu {
  @apply text-gray-400;

  a {
    @apply text-gray-400;
  }

  .ant-menu-title-content {
    @apply text-gray-400;
  }

  span {
    font-weight: 500;
  }

  :global(.ant-menu-item-icon) {
    border: 1.5px solid rgb(145, 151, 179);
    border-radius: 5px;
    padding: 4px;
    font-size: 1rem;
  }

  :global(.ant-menu-item-selected .ant-menu-item-icon),
  :global(.ant-menu-submenu-selected .ant-menu-item-icon) {
    background-color: @primary-color;
    border-color: @primary-color;
    border-radius: 50%;

    svg {
      fill: #fff;
    }
  }
}

.sider {
  @apply overflow-y-auto shadow-xl;

  :global(.ant-menu-inline-collapsed) {
    :global(.ant-menu-item .ant-menu-item-icon), :global(.ant-menu-submenu .ant-menu-submenu-title .ant-menu-item-icon) {
      line-height: 10px;
    }
  }
}

.header {
  @apply w-2/5 absolute top-0 pt-5 p-0 right-0;
  background-color: transparent;
  z-index: 1;
  height: min-content;
  line-height: 1rem;

  :global(.ant-input-affix-wrapper) {
    @apply h-10 rounded-xl;
  }
}

@primary-color: rgb(89, 50, 234);@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #fe6969;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@modal-mask-bg: fade(#fff, 85%);@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);