.auth {
  @apply flex flex-row-reverse justify-between h-screen;
}

.formSection {
  background-image: url('../../images/login-bg.png');
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.5);
  @apply flex flex-col w-full sm:w-1/2 h-full bg-cover sm:bg-none sm:shadow-none;
}

.form {
  @apply w-4/5 sm:w-3/5 m-auto;
}

.imageSection {
  @apply w-1/2 h-full hidden sm:flex bg-cover;
  background-image: url('../../images/login-bg.png');
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.5);
}

@primary-color: rgb(89, 50, 234);@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #fe6969;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@modal-mask-bg: fade(#fff, 85%);@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);