.flash {
  @apply absolute left-1/2 rounded-lg px-6 py-5 text-lightgrey-600 z-50;
  transition: all 0.3s ease-in-out;
  transform-origin: top center;
}

.error {
  background-color: #F9D2D2;
}

.success { 
  background-color: #D2F9E6;
}

.block {
  opacity: 1;
  top: 78px;
  transform: scale(1) translateX(-50%);
}

.hidden {
  opacity: 0.2;
  top: -120px;
  transform: scale(0) translateX(-50%);
}
@primary-color: rgb(89, 50, 234);@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #fe6969;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@modal-mask-bg: fade(#fff, 85%);@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);